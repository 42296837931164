::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #1D2946; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #1D2946; 
}
::-moz-selection {
    background: #a38f62;
    color: #fff;
}
::selection {
    background: #a38f62;
    color: #fff;
}
body {
    background-color: #fff;
    padding: 0;
    margin: 0;
}
.img-absolute{
    position: absolute;
    top: 0;
    width: 20%!important;
    left: 50%;
    transform: translateX(-50%);
}
a {
    color: #c1ab4e;
}
nav {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 250;
    background-color: #FFF;
    top: 0;
    display: none;
    animation: fadein .4s;
    overflow-y: auto;
}
#page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
#contenu {
    flex: 1;
}
.g-recaptcha { 
    visibility: hidden; 
    height: 0; 
}
.verify {
    transition: all 700ms ease;
    background-color: #fff;
    z-index: 77777777;
    position: fixed;
    padding: 15px;
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    padding: 4rem 0 1rem;
    overflow-y: auto;
}
.verify.off {
    opacity: 0;
    z-index: -7;
}
.verify img {
    max-width: 200px;
}
.verify p {
    max-width: 500px;
    margin: auto
}
.verify a {
    color: #c1ab4e;
}
.verify label {
    font-weight: bold;
    color: #c1ab4e;
}
.verify h2 {
    color: #c1ab4e;
    font-family: 'Comfortaa', cursive;
    font-weight: normal;
    text-shadow: none;
}
.btn {
    cursor: pointer;
}
.btn.btn-verify {
    background-color: #c1ab4e;
    border-color: #c1ab4e;
    color: #fff !important;
    text-transform: uppercase;
    padding: .3em 2.5em;
    border-radius: 30px;
}
.btn-verify:hover, .btn-verify:active, .btn-verify:focus {
    border-color: #c1ab4e;
}
/* Fin Verify age */
.overlay {
    display: none;
    position: fixed;
    top: 0;
    background-color: #1d2945;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    z-index: 777777777 !important;
}
.iconContainer {
    width: 300px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -100px;
}
.iconContainer p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    opacity: 0;
}
.phone {
    position: relative;
    left: 50%;
    margin-left: -80px;
    width: 0;
    height: 0;
    border: 0 solid #000;
    background: #a9a9a9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom:45px;
}
.phone i {
    text-align: center;
    width: 100%;
    line-height: 120px;
    font-size: 50px;
    color: #454545;
    opacity: 0;
}
@media only screen and (orientation: landscape) and (max-width: 820px) {
    .overlay {
        display: block;
        z-index:666;
    }
    .overlay .phone {
        -webkit-animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
        animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
    }
    .overlay .phone i {
        -webkit-animation: fadeIn .5s .8s forwards ease;
        animation: fadeIn .5s .8s forwards ease;
    }
    .overlay .iconContainer p {
        -webkit-animation: fadeIn .5s 1.3s forwards ease;
        animation: fadeIn .5s 1.3s forwards ease;
    }
}
@-webkit-keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@-webkit-keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@-webkit-keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@-webkit-keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
@keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
.z-1 {
    z-index: 1;
}
.z-2 {
    z-index: 2;
}
.more-text {
    position: relative;
    z-index: -1;
}
.logo-placement {
    position: absolute;
    top: 0;
    z-index: 200;
}
@media screen and (max-width: 991px) {
    .logo-placement {
        position: relative;
    }
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}
nav .menu {
    /*height: calc(100vh + 10vw);*/
    /*position:absolute;*/
    width:100%;
    /*top:0;*/
    padding: 2rem 0;
}
nav ul {
    padding: 0;
    list-style: none;
    display: block;
}
nav .menu-icon {
    padding: 8px;
}
nav div ul li {
    font-family: 'Comfortaa', cursive;
    font-size: 1.3vw;
    padding: .8vh 0;
}
nav div ul li a {
    color: #1d2945;
    text-decoration: none;
}
nav div ul li a:hover {
    text-decoration: none;
    color: #1d2945;
    font-weight: bold;
}
footer .bg {
    background-color: #1d2945;
    padding: 20px 80px;
}
footer .above {
    background-color: #f6f6f6;
    padding: 0 8px;
}
footer .above p {
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-align: center;
    letter-spacing: 1px;
    margin: 0;
}
footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
footer ul a, footer ul a:hover {
    color: white;
    text-decoration: none;
}
footer ul li {
    font-family: 'Karla', sans-serif;
    line-height: 200%;
    letter-spacing: 1px;
}
.mentions-legales a {
    text-decoration: none;
    color: #1d2945;
}
.mentions-legales h2 {
    color: #1d2945;
    margin-bottom: 1em;
    font-weight: 500;
    font-size: 1.2vw;
}
.mentions-legales p {
    margin-bottom: 3vh;
    font-size: 1vw;
}
.bg > div {
    width: 180px;
}
.logo-footer {
    width: auto;
    height: 75px;
}
.logo {
    width: 10vw;
    margin-top: 40px;
}
.burger {
    top: 20px;
    right: 20px;
    z-index: 220;
    cursor:pointer;
    background-color: #fff;
    border-radius: 32px;
    width: 50px;
    height: 50px;
    position: fixed;
}
.home .home-bloc, .cuvees, .cuvee, .wrap {
    position: relative;
}
.home > div {
    margin-top: 10vh;
}
.home > div:first-child {
    margin-top: 0;
}
.home h1, .home h2 {
    font-family: 'Caveat', cursive;
    font-size: 3.2vw;
    color: #c1ab4e;
}
.home span {
    display: block;
    font-family: 'Comfortaa', cursive;
    font-size: 2vw;
    color: #1d2945;
}
.home a, .home a:hover {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: bold;
    font-size: .9vw;
    text-decoration: none;
    text-transform: uppercase;
    color: #c1ab4e;
}
.home br {
    line-height: 0;
}
.home-bloc-1 {
    position: absolute;
    top: 14vw;
    right: 8vw;
    z-index:150;
}
.home-bloc-2 {
    position: absolute;
    top: 3vw;
    left: 8vw;
    z-index:150;
}
.home-bloc-3 {
    position: absolute;
    top: 9vw;
    right: 24vw;
    z-index:150;
}
.special {
    margin-top:0vh !important;
}
.home .home-btl {
    position:absolute;
    top:0;
    left:0;
}
.home .paralaxx1 {
    margin-top:4vw;
}
.cuvees h2, .cuvees-responsive h2 {
    font-family: 'Comfortaa', cursive;
    font-size: 2vw;
    color: #1d2945;
}
.cuvees h2 span, .cuvees-responsive h2 span {
    display: block;
    font-family: 'Caveat', cursive;
    font-size: 3.2vw;
    text-transform: capitalize;
    color: #c1ab4e;
}
.cuvees a, .cuvees-responsive a, .histoire a, .sparkling a, .readMore, .cuvees b.gris {
    font-family: unset;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: bold;
    font-size: .9vw;
    text-decoration: none;
    text-transform: uppercase;
    color: #d6d6d6;
    cursor: pointer;
}
.cuvees-responsive h2 {
    font-family: 'Comfortaa', cursive;
    font-size: 1.3em;
    color: #1d2945;
}
.cuvees-responsive h2 span {
    display: block;
    font-family: 'Caveat', cursive;
    font-size: 1.9em;
    color: #c1ab4e;
}
.cuvees-responsive a {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: bold;
    font-size: .7em;
    text-decoration: none;
    text-transform: uppercase;
    color: #d6d6d6;
}
.cuvees-bloc-1 {
    position: absolute;
    top: 0;
    left: 18vw;
    z-index: 150;
}
.cuvees-bloc-2 {
    position: absolute;
    top: 0;
    right: 18vw;
    z-index: 150;
}
.cuvees-bloc-1 h2, .cuvees-bloc-2 h2 {
    font-size: 2vw;
}
.cuvees-responsive {
    display: none;
    margin: 20px 0;
}
.cuvees .left .static, .cuvees .right .static {
    position: absolute;
    background: white;
    top: 0;
}
.left, .right {
    min-height: 70vh;
    margin-top: 10vh;
}
.cuvees .hover-back a {
    height: 100%;
    width: 100%;
    display: block;
}
.hide {
    opacity: 0;
}
.cuvee {
    margin: 0 0 10px 0;
}
.cuvee h1, .sparkling h2, .histoire h1, .histoire h2 {
    font-family: 'Comfortaa', cursive;
    font-size: 2vw;
    color: #1d2945;
    padding-bottom: 1vh;
}
.cuvee h1 span, .sparkling h2 span, .histoire h1 span, .histoire h2 span {
    display: block;
    font-family: 'Caveat', cursive;
    font-size: 3.2vw;
    color: #c1ab4e;
}
.cuvee p, .histoire p, .sparkling p {
    font-family: 'Raleway', sans-serif;
    font-size: .9vw;
}
.rupture-1, .more-text {
    display: none;
}
.pl-6 {
    padding-left: 6rem;
}
.my-6 {
    margin: 1em 0;
}
.histoire .first-block {
    margin-top: 10vw;
}
.sparkling .first-block {
    margin-top: 17vw;
}
.mentions-legales .first-block {
    margin-top: 18vw;
}
.sparkling .bloc-img-1 .fond {
    position:absolute;
    top:0;
}
.sparkling .bloc-img-2 .fond {
    position:absolute;
    top:0;
}
.sparkling .paralaxx2 {
    margin-top:15vw;
}
.cuvee .first-block {
    margin-top: 15vw;
}
.cuvees {
    margin-top: 8vw;
    z-index: 100;
}
.cuvees .left .full, .cuvees .right .full {
    width: 100%;
    margin-top: -23vh;
    z-index: -7;
    position: relative;
}
.cuvees .left .img-color {
    position: absolute;
    right: 3vw;
    top: 0;
    width: 9vw;
    transition: all 300ms ease;
    opacity: 0;
    z-index: -7;
}
.cuvees .left .img-illu {
    position: absolute;
    right: 3vw;
    top: 0;
    width: 9vw;
    transition: all 300ms ease;
}
.cuvees .right .img-color {
    position: absolute;
    left: 3vw;
    top: 0;
    width: 9vw;
    transition: all 300ms ease;
    opacity: 0;
    z-index: -7;
}
.cuvees .right .img-illu {
    position: absolute;
    left: 3vw;
    top: 0;
    width: 9vw;
    transition: all 300ms ease;
}
.cuvees a:hover .img-illu {
    z-index: -7;
}
.cuvees a:hover .img-color {
    opacity: 1;
}
.histoire > div > div {
    margin-bottom: 6rem;
}
.sparkling-text {
    position: absolute;
    left: 8vw;
    top: -8vw;
}
.middle {
    margin-top: 4vw;
    margin-bottom: 12vw;
}
.info-logo img {
    width: auto;
    height: 50px;
}
.social-logo img {
    width: auto;
    height: 33px;
}
.rupture-0 {
    text-align: center;
}
.cuvee-mobile {
    position: absolute;
    top: 0;
    left: 0;
}
.home .mobile {
    display:none;
}
#contact-form {
    font-family: 'Raleway', sans-serif;
    font-size: .9em;
}
#contact-form input, #contact-form select {
    border:none;
    border-bottom:1px solid #1F3654;
    color:#1F3654;
    border-radius:0;
    font-size:.9vw;
}
#contact-form input::placeholder {
    color:#1F3654;
}
#contact-form button {
    font-family: 'Caveat', cursive;
    font-size: 3.2vw;
    color: #c1ab4e;
    background:none;
    border:0;
    text-align:right;
}
#contact-form textarea {
    border:none;
    border-bottom:1px solid #1F3654;
    color:#1F3654;
    border-radius:0;
    font-size:.9vw;
}
#contact-form textarea::placeholder {
    color:#1F3654;
}
@media screen and (max-width: 767.98px) {
    .verify img {
        max-width: 100px;
    }
}
@media screen and (max-width: 991px) {
    .mentions-legales .first-block {
        margin-top: 0;
    }
    .sparkling .bloc-img-1 .fond {
        position:relative;
    }
    .sparkling .bloc-img-1 .paralaxx1 {
        position:absolute;
        top:0;
        left:0;
        margin-top:30vw;
    }
    .sparkling .bloc-img-2 .paralaxx2 {
        top:0;
        left:0;
        margin-top:50vw;
    }
    .rupture-0 {
        display: none;
    }
    .rupture-1 {
        display: block;
    }
    .sparkling .rupture-1 {
        margin-top:-20vw;
    }
    .home {
        text-align: center;
    }
    .home h1, .home h2 {
        font-size: 2.4em;
    }
    .home span {
        font-size: .7em;
    }
    .home a, .home a:hover {
        font-size: 1em;
    }
    .home-bloc-1, .home-bloc-2, .home-bloc-3 {
        position: relative;
        top: unset;
        right: unset;
        left:0;
        margin: 0 8px;
    }
    .home-bloc-1 {
        margin-top: 10vh;
        position: relative;
        z-index: 150;
    }
    .home-bloc-2 {
        margin-bottom:18vw;
    }
    .home-bloc-3 {
        margin-top:18vw;
    }
    .home .mobile {
        display:block;
    }
    .home .desktop {
        display:none;
    }
    .burger {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    footer .bg {
        background-color: #1d2945;
        padding: 20px 50px;
    }
    .cuvees {
        display: none;
    }
    .cuvees-responsive {
        display: block;
    }
    .cuvee h2 {
        font-family: 'Comfortaa', cursive;
        font-size: 1.3em;
        color: #1d2945;
        padding-bottom: 1vh;
    }
    .cuvee h2 span {
        display: block;
        font-family: 'Caveat', cursive;
        font-size: 1.9em;
        color: #c1ab4e;
    }
    .cuvee p {
        font-family: 'Raleway', sans-serif;
        font-size: .9em;
    }
    .cuvee h1 {
        font-size: 1.3em;
    }
    .cuvee h1 span {
        font-size: 1.9em;
    }
    .cuvee p {
        font-size: .9em;
    }
    .cuvee img {
        margin-bottom: 30px!important;
        margin: auto;
    }
    .histoire, .sparkling {
        text-align: center;
    }
    .histoire h1, .histoire h2, .sparkling h2 {
        font-size: 1.9em;
    }
    .histoire h1 span, .histoire h2 span, .sparkling h2 span {
        font-size: 1.1em;
    }
    .histoire p, .sparkling p {
        font-size: .9em;
    }
    .histoire a, .sparkling a, p .read-more {
        font-size: .8em;
    }
    .histoire > div > div {
        margin-bottom: 2rem;
    }
    .histoire .reveal-2 {
        margin-top:10vw;
    }
    .wrap {
        display: none;
    }
    .pl-6 {
        padding-left: 0;
    }
    .middle {
        margin-top: 16vw;
        margin-bottom: 14vw;
    }
    nav div ul li {
        font-size: 1.2em;
    }
    .logo {
        height: 140px;
        margin-top: 20px;
        width: auto;
    }
    .logo-footer {
        height: 90px;
    }
    .sparkling img {
        margin-top: 30px;
    }
    .mentions-legales {
        text-align: center;
    }
    .mentions-legales h2 {
        font-size: 1.2em;
    }
    .mentions-legales p {
        font-size: 1em;
    }
    #contact-form input, #contact-form select {
        font-size:14px;
    }
    #contact-form textarea {
        font-size:14px;
    }
    #contact-form button {
        font-size: 6vw;
        text-align:center;
    }
    /*nav .menu {*/
    /*    height: calc(100vh + 25vw);*/
    /*}*/
}
@media screen and (max-width: 576px) {
    .bg > div {
        margin-right: auto;
        margin-left: auto;
        padding: 10px;
        text-align: center;
    }
}
.cuvee-url {
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    color: #c1ab4e;
}
.cuvee-url:hover {
    text-decoration: none;
    color: #c1ab4e;
}
.customPrevBtn, .customNextBtn {
    width: 12px;
}
.customNextBtn, .customPrevBtn {
    cursor: pointer;
}
.onb .owl-stage-outer:before {
    position: absolute;
    display: block;
    content: '';
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.onb .owl-stage-outer:before {
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 65%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 65%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 65%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
.onb {
    cursor: pointer;
}
.hide {
    display: none;
}
.triman {
    width: 55px;
}

.o-25 {
    opacity: .25;
    cursor: default
}